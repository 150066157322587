import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Image from "./../components/image";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="mdx-content">
      <h1>{`O devstyle`}</h1>
      <p>{`devstyle działa na polskim rynku IT od 2007 roku. Zajmujemy się edukacją polskich programistów - na najwyższym poziomie. Wydajemy najwyższej jakości szkolenia online, kierowane do wymagających specjalistów.`}</p>
      <p>{`Współpracujemy wyłącznie z najlepszymi, uznanymi Ekspertami. To praktycy z olbrzymim doświadczeniem, jednocześnie potrafiący przekazać wiedzę w przystępny sposób. Dbamy przede wszystkim o merytorykę materiałów i ich bezpośrednie przełożenie na rozwój kariery Uczestników naszych Programów!`}</p>
      <Image className="py-6 lg:py-10 mx-auto w-auto" src="content/o-nas-1.svg" alt="" mdxType="Image" />
      <p>{`Przy wyborze tematów i Ekspertów kierujemy się zasadą: ma być najlepiej, a nie najwięcej. Dlatego w naszej ofercie nie znajdziesz setek “kursów online”. Skupiamy się na tych konkretnych tematach, w których jesteśmy w stanie dostarczyć największą, uniwersalną wartość.`}</p>
      <h2>{`Materiały w devstyle to:`}</h2>
      <ul>
        <li parentName="ul">{`zawsze najwyższa jakość`}</li>
        <li parentName="ul">{`tematy niezbędne w programistycznej edukacji`}</li>
        <li parentName="ul">{`najlepsi Eksperci`}</li>
        <li parentName="ul">{`materiały video, audio, tekst, slajdy`}</li>
        <li parentName="ul">{`kod do pracy i zadania domowe`}</li>
        <li parentName="ul">{`wspólna nauka w społeczności każdego szkolenia i interakcja z Mentorami`}</li>
        <li parentName="ul">{`gwarancja zadowolenia (albo zwrot 100% pieniędzy!)`}</li>
      </ul>
      <Image className="py-6 lg:py-10 mx-auto w-auto" src="content/o-nas-2.svg" alt="" mdxType="Image" />
      <p>{`Zaufało nam już ponad 20 tysięcy polskich specjalistów! Na pokładzie mamy też wiele firm, dbających o rozwój swoich pracowników i zapewniających edukację całym programistycznym zespołom.`}</p>
      <p>{`Wierzymy, że dzięki naszym szkoleniom polskie programistki i programiści wykonują swoją codzienną pracę z większym zaangażowaniem, lepszymi umiejętnościami i… uśmiechem na ustach :).`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      